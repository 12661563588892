<template src="./Platinum.html" />
<style lang="scss" scoped src="./Platinum.scss"></style>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import useMe from '@/comporsables/useMe'
import HeaderRight from '@/compornents/HeaderRight/HeaderRight'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import BusinessProfile from '@/compornents/BusinessProfile/BusinessProfile'
import { getWindowHeight } from '@/utils/window'
export default defineComponent({
  name: 'Platinum',
  title() {
    return 'プラチナ会員'
  },
  components: {
    HeaderRight,
    AvatarImg,
    BusinessProfile
  },
  setup(_, { root }) {
    const { me } = useMe(root)
    const isManage = computed(() => root.$store.getters.isManage)
    const layer1 = computed(() => root.$route.params.layer1)
    const layer2 = computed(() => root.$route.params.layer2)

    if (!isManage.value) {
      root.$router.push({ name: 'home' })
    }

    return {
      loading: computed(() => root.$store.getters.loading),
      isSp: computed(() => root.$store.getters.isSp),
      me,
      layer1,
      layer2,
      windowHeight: getWindowHeight(74)
    }
  }
})
</script>
