<template src="./ManagePlatinum.html" />
<style lang="scss" scoped src="./ManagePlatinum.scss"></style>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import HeaderRight from '@/compornents/HeaderRight/HeaderRight'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import ManageMenu from '@/compornents/ManageMenu/ManageMenu'

export default defineComponent({
  name: 'ManagePlatinum',
  title() {
    return 'みんなでケアプラチナ - 介護事業者管理画面'
  },
  components: {
    HeaderRight,
    AvatarImg,
    ManageMenu
  },
  setup(_, { root }) {
    const isSp = computed(() => root.$store.getters.isSp)
    const isManage = computed(() => root.$store.getters.isManage)

    // SPまたは事業管理者以外の場合はホームへ
    if (isSp.value || !isManage.value) {
      root.$router.push({ name: 'home' })
    }

    return {
      loading: computed(() => root.$store.getters.loading)
    }
  }
})
</script>
