import { render, staticRenderFns } from "./ManagePlatinum.html?vue&type=template&id=daa6ac4e&scoped=true&"
import script from "./ManagePlatinum.vue?vue&type=script&lang=js&"
export * from "./ManagePlatinum.vue?vue&type=script&lang=js&"
import style0 from "./ManagePlatinum.scss?vue&type=style&index=0&id=daa6ac4e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "daa6ac4e",
  null
  
)

export default component.exports